import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Chip, Stack } from "@mui/material";
import FormModal from "./FormModal";
import { API_BASE_URL } from "../config/api";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter((user) => {
        // Create a searchable string that includes all user data
        const searchableText = [
          user.userID?.toString() || '',
          user.balance?.toString() || '',
          user.toWithdraw?.toString() || '',
          user.stats || '',
          user.value?.toString() || '',
          user.inviter?.toString() || '',
          user.total_invites?.toString() || '',
          // Include wallet information in search
          Object.entries(user.wallets || {})
            .map(([type, address]) => `${type} ${address}`)
            .join(' ')
        ].join(' ').toLowerCase();

        return searchableText.includes(searchQuery.toLowerCase());
      })
    );
    setPage(0);
  }, [searchQuery, users]);

  const fetchUsers = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await axios.get(`${API_BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAdd = async (user) => {
    const token = localStorage.getItem("adminToken");
    try {
      // Convert wallet string inputs to wallet object
      const wallets = {};
      const walletEntries = user.wallets?.split(',') || [];
      walletEntries.forEach(entry => {
        const [type, address] = entry.split(':').map(s => s.trim());
        if (type && address) {
          wallets[type] = address;
        }
      });

      const userData = {
        ...user,
        wallets
      };

      await axios.post(`${API_BASE_URL}/users`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleEdit = async (user) => {
    const token = localStorage.getItem("adminToken");
    try {
      // Convert wallet string inputs to wallet object
      const wallets = {};
      const walletEntries = user.wallets?.split(',') || [];
      walletEntries.forEach(entry => {
        const [type, address] = entry.split(':').map(s => s.trim());
        if (type && address) {
          wallets[type] = address;
        }
      });

      const userData = {
        ...user,
        wallets
      };

      await axios.put(`${API_BASE_URL}/users/${user._id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUsers();
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.delete(`${API_BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const formatWallets = (wallets) => {
    if (!wallets) return '';
    return Object.entries(wallets)
      .map(([type, address]) => `${type}:${address}`)
      .join(', ');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the current users to display based on pagination
  const currentUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <h2>Users</h2>
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
        style={{ marginBottom: "20px" }}
      />
      <Button variant="contained" color="primary" onClick={() => setShowModal(true)} style={{ marginBottom: "20px" }}>
        Add User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>UserID</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>To Withdraw</TableCell>
              <TableCell>Stats</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Inviter</TableCell>
              <TableCell>Total Invites</TableCell>
              <TableCell>Wallets</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentUsers.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.userID}</TableCell>
                <TableCell>{user.balance}</TableCell>
                <TableCell>{user.toWithdraw}</TableCell>
                <TableCell>{user.stats}</TableCell>
                <TableCell>{user.value}</TableCell>
                <TableCell>{user.inviter}</TableCell>
                <TableCell>{user.total_invites}</TableCell>
                <TableCell>
                  <Stack direction="column" spacing={1}>
                    {user.wallets && Object.entries(user.wallets).map(([type, address]) => (
                      <Chip
                        key={type}
                        label={`${type}: ${address}`}
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => { 
                      setEditingUser({
                        ...user,
                        wallets: formatWallets(user.wallets)
                      }); 
                      setShowModal(true); 
                    }}
                  >
                    Edit
                  </Button>
                  <Button variant="contained" color="error" onClick={() => handleDelete(user._id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FormModal
        show={showModal}
        onClose={() => { setShowModal(false); setEditingUser(null); }}
        onSubmit={editingUser ? handleEdit : handleAdd}
        initialData={editingUser}
        fields={["userID", "balance", "toWithdraw", "stats", "value", "inviter", "total_invites", "wallets"]}
      />
    </div>
  );
};

export default Users;