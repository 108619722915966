import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import Users from "./components/Users";
import Tasks from "./components/Tasks";
import TaskSubmissions from "./components/TaskSubmissions";
import Withdrawals from "./components/Withdrawals";
import AdminSettings from "./components/AdminSettings";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

const Navigation = () => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) return null;

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>Admin Panel</Typography>
        <Button color="inherit" component={Link} to="/users">Users</Button>
        <Button color="inherit" component={Link} to="/tasks">Tasks</Button>
        <Button color="inherit" component={Link} to="/task-submissions">Task Submissions</Button>
        <Button color="inherit" component={Link} to="/withdrawals">Withdrawals</Button>
        <Button color="inherit" component={Link} to="/admin-settings">Admin Settings</Button>
        <Button color="inherit" onClick={logout}>Logout</Button>
      </Toolbar>
    </AppBar>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Navigation />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/tasks"
              element={
                <PrivateRoute>
                  <Tasks />
                </PrivateRoute>
              }
            />
            <Route
              path="/task-submissions"
              element={
                <PrivateRoute>
                  <TaskSubmissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/withdrawals"
              element={
                <PrivateRoute>
                  <Withdrawals />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-settings"
              element={
                <PrivateRoute>
                  <AdminSettings />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;