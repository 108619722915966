import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import { API_BASE_URL } from "../config/api";

const TaskSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchSubmissions();
  }, []);

  useEffect(() => {
    const filtered = submissions.filter((submission) =>
      Object.values(submission)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredSubmissions(filtered);
    setPage(0);
  }, [searchTerm, submissions]);

  const fetchSubmissions = async () => {
    const token = localStorage.getItem("adminToken");
    try {
      const response = await axios.get(`${API_BASE_URL}/task-submissions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubmissions(response.data);
      setFilteredSubmissions(response.data);
    } catch (error) {
      console.error("Error fetching submissions:", error);
    }
  };

  const handleApprove = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.put(`${API_BASE_URL}/task-submissions/${id}`, 
        { status: "approved" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchSubmissions();
    } catch (error) {
      console.error("Error approving submission:", error);
    }
  };

  const handleReject = async (id) => {
    const token = localStorage.getItem("adminToken");
    try {
      await axios.put(`${API_BASE_URL}/task-submissions/${id}`, 
        { status: "rejected" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchSubmissions();
    } catch (error) {
      console.error("Error rejecting submission:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentSubmissions = filteredSubmissions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <h2>Task Submissions</h2>
      <TextField
        label="Search Task Submissions"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Task ID</TableCell>
              <TableCell>Proof</TableCell>
              <TableCell>Reviewed</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentSubmissions.map((submission) => (
              <TableRow key={submission._id}>
                <TableCell>{submission.userId}</TableCell>
                <TableCell>{submission.taskId}</TableCell>
                <TableCell>{submission.proof.text}</TableCell>
                <TableCell>{submission.reviewed ? "Yes" : "No"}</TableCell>
                <TableCell>{new Date(submission.timestamp).toLocaleString()}</TableCell>
                <TableCell>{submission.status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApprove(submission._id)}
                    // disabled={submission.status === "approved" || submission.status === "rejected"}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleReject(submission._id)}
                    // disabled={submission.status === "approved" || submission.status === "rejected"}
                  >
                    Reject
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredSubmissions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TaskSubmissions;